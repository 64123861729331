<template>
   <v-data-table v-model="table.selected" :headers="columns" :items="filteredRecords" dense show-select item-key="id"
      :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60, -1]}" :hide-default-footer="!records.length"
      >
      <template v-slot:top>
         <v-toolbar flat>
            <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
               class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
               />
            <v-select v-model="table.filter.status" :items="records.map(j => j.status).filter((value, index, self) => self.indexOf(value) === index)" placeholder="Status"
               :return-object="false" :menu-props="{ bottom: true, offsetY: true }" multiple hide-details clearable
               class="mr-3 hidden-sm-and-down" style="max-width: 270px;"
               >
               <template v-slot:selection="{ item, index }">
                  <span v-if="index < 1">{{ index === 0 ? item : `, ${item}` }}</span>
                  <span v-if="index === 1" class="grey--text ml-2">
                     ( +{{ table.filter.status.length - 1 }} more )
                  </span>
               </template>
            </v-select>
            <v-select v-model="table.filter.organization" :items="records.map(j => j.organization).filter((value, index, self) => self.indexOf(value) === index)" placeholder="Organization"
               :return-object="false" :menu-props="{ bottom: true, offsetY: true }" multiple hide-details clearable
               class="mr-3 hidden-sm-and-down" style="max-width: 270px;"
               >
               <template v-slot:selection="{ item, index }">
                  <span v-if="index < 1">{{ index === 0 ? item : `, ${item}` }}</span>
                  <span v-if="index === 1" class="grey--text ml-2">
                     ( +{{ table.filter.organization.length - 1 }} more )
                  </span>
               </template>
            </v-select>
            <v-spacer />
            <v-btn v-if="$hasRole(report.kind.toLowerCase(), 'E')" x-large icon tile color="green darken-4" class="mx-1" :loading="process.xls" :disabled="!table.selected.length" @click="downloadXLS">
               <v-icon size="44">
                  mdi-file-excel
               </v-icon>
            </v-btn>
            <v-btn v-if="$hasRole(report.kind.toLowerCase(), 'P')" x-large icon tile color="red darken-3" class="mx-1" :loading="process.pdf" :disabled="!table.selected.length" @click="downloadPDF">
               <v-icon size="44">
                  mdi-file-pdf
               </v-icon>
            </v-btn>
         </v-toolbar>
         <v-divider />
      </template>
      <template v-slot:no-data>
         <div class="my-5" style="font-size: 1.15rem;">
            No data available
         </div>
      </template>
   </v-data-table>
</template>

<script>
import { version } from '../../../package'
import { imageFromUrl, pastel } from '@/utils/nirtara'
const toDataURL = url => fetch(url)
.then(response => response.blob())
.then(blob => new Promise((resolve, reject) => {
   const reader = new FileReader()
   reader.onloadend = () => resolve(reader.result)
   reader.onerror = reject
   reader.readAsDataURL(blob)
}))
export default {
   name: 'NApprovalSummary',

   props: {
      report: { type: Object },
      records: { type: Array },
      letterHead: { type: String },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Name', value: 'name', align: ' pl-0' },
         { text: 'Phone', value: 'phone', width: 160 },
         { text: 'Email', value: 'email' },
         { text: 'Organization', value: 'organization' },
         { text: 'Floor', value: 'floor', width: 150 },
         { text: 'Updated At', value: 'updatedAt', width: 170 },
         { text: 'Status', value: 'status', width: 100 },
         // { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      table: { search: '', filter: { status: [], organization: [] }, selected: [] },
      process: { pdf: false, xls: false },
   }),

   computed: {
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            var filteredPhone = j.phone && j.phone.toLowerCase().indexOf(search) !== -1
            var filteredEmail = j.email && j.email.toLowerCase().indexOf(search) !== -1
            return (!search || filteredName || filteredPhone || filteredEmail) &&
                   (!this.table.filter.status.length || this.table.filter.status.includes(j.status)) &&
                   (!this.table.filter.organization.length || this.table.filter.organization.includes(j.organization))
         })
      },
   },
   mounted () {
   },
   methods: {
      async downloadXLS () {
         this.process.xls = true
         // console.log(`downloadXLS ( table.selected.length = ${this.table.selected.length} )`, JSON.parse(JSON.stringify(this.table.selected)))
         const workbook = new this.$excelJS.Workbook()
         workbook.creator = 'NIRTARA Visitor Management'
         const worksheet = workbook.addWorksheet(this.report.name)

         worksheet.columns = this.columns.map(j => ({ header: j.text, key: j.value }))
         worksheet.getRow(1).eachCell(cell => { cell.style = { font: { bold: true, size: 12 } } })
         let keys = []
         this.table.selected.map(j => { worksheet.addRow(j); keys.push(j.organization) })

         this.$excelJS.autoWidth(worksheet)

         keys = keys.filter((value, index, self) => self.indexOf(value) === index)
         const background = pastel(1 + keys.length)
         const rules = keys.map((t, i) => ({
            type: 'expression',
            formulae: [`$D2="${t}"`],
            style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: background[i] } } },
         }))
         worksheet.addConditionalFormatting({ ref: `A2:${this.$excelJS.numberToLetters(Object.keys(this.columns).length - 1)}${1 + this.table.selected.length}`, rules: rules })

         const saveAs = require('file-saver')
         const buffer = await workbook.xlsx.writeBuffer()
         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
         saveAs(blob, `NVM-50-WEB-${this.report.name}-${this.$moment().format('YYYYMMDD-HHmmss')}.xlsx`)
         this.process.xls = false
      },
      async downloadPDF () {
         this.process.pdf = true
         // console.log(`downloadPDF ( table.selected.length = ${this.table.selected.length} )`, JSON.parse(JSON.stringify(this.table.selected)))
         const activities = []
         // this.table.selected.map(j => {
         for (var idx = 0; idx < this.table.selected.length; idx++) {
            const j = this.table.selected[idx]
            activities.push([{ text: `Tenant: ${j.organization}`, colSpan: 2 }, '', { text: `Operator: ${j.operator || 'Self'}`, colSpan: 2, alignment: 'right' }, ''])
            activities.push([j.name ? `Visitor: ${j.name}` : 'Unregisted Visitor', j.floor || 'N/A', j.updatedAt, j.status])
            const card = j.Card && await toDataURL(imageFromUrl(j.Card))
            const face = j.Face && await toDataURL(imageFromUrl(j.Face))
            if (face && card) activities.push([{ columns: [{ image: face, fit: [100, 100], width: 'auto' }, { image: card, fit: [200, 100], width: '*' }], columnGap: 8, colSpan: 4, margin: [0, 5, 0, 0] }])
            else if (face) activities.push([{ columns: [{ image: face, fit: [100, 100], width: 'auto' }], columnGap: 8, colSpan: 4, margin: [0, 5, 0, 0] }])
            else if (card) activities.push([{ columns: [{ image: card, fit: [200, 100], width: '*' }], columnGap: 8, colSpan: 4, margin: [0, 5, 0, 0] }])
            else activities.push(['', '', '', ''])
         }
         // })
         const _ = require('lodash')
         const grouped = _.chain(this.table.selected).groupBy('organization').value()
         const summary = Object.keys(grouped).map(key => {
            return [key, { text: grouped[key].length, alignment: 'right' }]
         })
         const background = this.letterHead
         const cover = [
            { text: 'APPROVAL SUMMARY REPORT', style: 'boldTitle', margin: [0, 30, 0, 0] },
            { text: 'Contains information about visitors, tenants and approvals', fontSize: 10, italics: true, alignment: 'center' },
            { image: 'logo', width: 120, alignment: 'center', margin: [0, 20, 0, 20] },
            { text: `Prepared by ${this.$store.state.user.name}`, fontSize: 14, alignment: 'center', margin: [0, 0, 0, 10] },
            { text: `${this.$store.state.setting.global.name} version ${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}`, fontSize: 10, italics: true, alignment: 'center' },
            { text: this.$store.state.setting.global.title, alignment: 'center', pageBreak: 'after' },
         ]
         const executive = [
            { text: 'EXECUTIVE SUMMARY', style: 'boldTitle', margin: [0, 30, 0, 50] },
            {
               layout: {
                  hLineWidth: function (i, node) {
                     if (i === 0 || i === node.table.body.length) return 0
                     return (i === node.table.headerRows) ? 2 : 1
                  },
                  vLineWidth: function (i) { return 0 },
                  hLineColor: function (i) { return i === 1 ? 'black' : '#aaa' },
                  paddingLeft: function (i) { return i === 0 ? 0 : 8 },
                  paddingRight: function (i, node) { return (i === node.table.widths.length - 1) ? 0 : 8 },
                  paddingTop: function (i) { return i === 0 ? 0 : 5 },
                  paddingBottom: function (i) { return i === 0 ? 2 : 5 },
               },
               table: {
                  headerRows: 1,
                  widths: ['*', 'auto'],
                  body: [
                     [{ text: 'Tenant Organization', style: 'boldBlack' }, { text: 'Count', style: 'boldBlack' }],
                     // ['Register', { text: '1,000', alignment: 'right' }],
                     ...summary,
                  ],
               },
               pageBreak: 'after',
            },
         ]
         const details = [
            { text: 'ACTIVITY DETAILS', style: 'boldTitle', margin: [0, 30, 0, 50] },
            {
               layout: {
                  hLineWidth: function (i, node) {
                     if (i === 0 || i === node.table.body.length) return 0
                     return (i === node.table.headerRows) ? 3 : (i % 3 === 1) ? 1 : 0
                  },
                  vLineWidth: function (i) { return 0 },
                  hLineColor: function (i) { return i === 1 ? 'black' : '#aaa' },
                  paddingLeft: function (i) { return i === 0 ? 0 : 8 },
                  paddingRight: function (i, node) { return (i === node.table.widths.length - 1) ? 0 : 8 },
                  paddingTop: function (i) { return i === 0 ? 0 : (i % 3 === 0) ? 0 : 5 },
                  paddingBottom: function (i) { return i === 0 ? 2 : (i % 3 === 0) ? 5 : 0 },
               },
               table: {
                  headerRows: 1,
                  widths: ['*', 'auto', 'auto', 'auto'],
                  body: [
                     [{ text: 'Name', style: 'boldBlack' }, { text: 'Floor', style: 'boldBlack' }, { text: 'Updated At', style: 'boldBlack' }, { text: 'Status', style: 'boldBlack' }],
                     // [{ text: 'ID: 0123456789' }, '', '', ''],
                     // ['Administrator', '021', 'nvm@50.web', 'Super Administrator'],
                     ...activities,
                  ],
               },
               pageBreak: 'after',
            },
         ]
         const acknowledgment = [
            { text: 'ACKNOWLEDGMENT', style: 'boldTitle', margin: [0, 30, 0, 50] },
            {
               columns: [
                  { width: '33%', text: `Prepared by,\n\n\n\n\n\n\n\n\n${this.$store.state.user.name}`, alignment: 'center' },
                  { width: '*', text: 'Checked by,\n\n\n\n\n\n\n\n\nReviewer', alignment: 'center' },
                  { width: '33%', text: 'Approved by,\n\n\n\n\n\n\n\n\nApprover', alignment: 'center' },
               ],
               columnGap: 10,
               alignment: 'center',
            },
         ]
         const moment = this.$moment()
         const that = this
         var docDefinition = {
            pageSize: 'A4',
            pageMargins: [70, 140, 40, 50],
            background: function (currentPage, pageSize) {
               return {
                  image: background,
                  width: pageSize.width,
               }
            },
            header: function (currentPage, pageCount, pageSize) {
               const text = (currentPage === 1) ? `\n\n\n\nTotal: ${pageCount} sheets\n${moment.format('dddd, YYYY-MM-DD')}`
                            : (currentPage === pageCount) ? 'Approval Summary Report'
                            : (currentPage > 2) ? `Page ${currentPage - 2} of ${pageCount - 3}`
                            : ''
               return { text: text, alignment: 'right', margin: 20 }
            },
            content: [
               ...cover, // cover page
               ...executive, // executive summary
               ...details, // details data
               ...acknowledgment, // acknowledgment
            ],
            footer: function (currentPage, pageCount) {
               return currentPage === 1 ? '' : {
                  columns: [
                     { width: '30%', text: `${that.$store.state.setting.global.name} version ${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}` },
                     { width: '*', text: that.$store.state.setting.global.title, alignment: 'center' },
                     { width: '30%', text: moment.format('dddd, YYYY-MM-DD HH:mm:ss'), alignment: 'right' },
                  ],
                  margin: [20, 20, 20, 0],
               }
            },
            images: {
               logo: await toDataURL(require('@/assets/logo.png')), // logo.svg
            },
            defaultStyle: { fontSize: 11, color: 'grey' },
            styles: {
               boldTitle: {
                  bold: true,
                  color: '#204165',
                  fontSize: 20,
                  alignment: 'center',
               },
               boldBlack: {
                  bold: true,
                  color: 'black',
               },
            },
         }
         this.$pdfMake.createPdf(docDefinition).download(`NVM-50-WEB-${this.report.name}-${this.$moment().format('YYYYMMDD-HHmmss')}.pdf`, () => { this.process.pdf = false })
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
</style>
